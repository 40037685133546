import React, { useState, useLayoutEffect } from 'react';

import SignInBg from '../images/login.png';

import { useTrackingView } from '../shared/hooks/useTrackingView';
import { useAptiveAuth } from '../shared/hooks/AptiveAuth';
import { useNavigate } from 'react-router-dom';

import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { useAptiveAuthContext } from 'shared/hooks/AptiveAuth/AptiveAuthProvider';
import { generateApiUrlByVersion } from 'services/CustomerService';

import { useFeatureFlag, User } from 'configcat-react';

interface AuthPageProps {
  company?: string;
}

const AuthPage: React.FC<AuthPageProps> = ({ company }) => {
  const savedEmail = localStorage.getItem('AptiveQA') || '';
  const userObject = new User(savedEmail);

  const { useLegacyAuthProvider } = useAptiveAuthContext();
  const { loginWithRedirect, isAuthenticated, startLogin, startSignUp } = useAptiveAuth();
  const { value: maintenanceMode, loading: maintenanceModeLoading } = useFeatureFlag('maintenanceMode', false, userObject);

  useLayoutEffect(() => {
    if (company) {
      localStorage.setItem('userCompany', company);
    }

    if (maintenanceMode) {
      return navigate('/maintenance');
    }

    if (useLegacyAuthProvider) {
      if (!isAuthenticated) {
        return loginWithRedirect?.();
      } else {
        return navigate('/dashboard');
      }
    } else {
      if (isAuthenticated) {
        return navigate('/dashboard');
      }
    }

  }, [isAuthenticated, maintenanceMode, company]);

  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const apiUrl = `${generateApiUrlByVersion(1)}`;

  const navigate = useNavigate();
  useTrackingView();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    if (error) {
      setError(null);
    }
  };

  const verifyCustomerInPestRoutes = async (e: any) => {
    e.preventDefault();
    if (!email) {
      setError('Email field is empty.');
      return;
    }

    setError(null);
    setIsLoading(true);
    const url = apiUrl + '/emailcheck';
    const payload = { email: email.toLowerCase(), auth: 'fusion' };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      const exists = data.exists;
      const has_logged_in = data.has_logged_in;
      const has_registered = data.has_registered;

      if (!exists) {
        setError(`We can't find an account with that email.`);
        setIsLoading(false);
      } else {
        if (has_logged_in || has_registered) {
          if (data?.company) {
            localStorage.setItem('userCompany', data.company);
          } else {
            localStorage.removeItem('userCompany');
          }

          startLogin?.(email);
        } else {
          startSignUp?.(email);
        }
      }
    } catch {
      setError('An error occurred while checking your email. Please try again later.');
      setIsLoading(false);
    }
  };

  return (
    <div className="flex w-full">
      <div className="flex w-full">
        <div className="flex justify-center lg:justify-end w-full lg:w-1/2 pb-20 lg:pr-12 xl:pr-32">
          <div className="flex flex-col md:w-[450px] w-full md:pt-32 lg:pl-4 px-4 pt-20">
            <div id="login-step-1" className="step-wrapper">
              <div className="content-title flex flex-col">
                <h2 className="text-3xl font-bold mb-4">Welcome</h2>
                <p className="text-sm mb-3">To get started, enter the email you used when signing up for Aptive Service</p>
                <p className="text-sm mb-2">
                  <span>Not an Aptive customer?</span>{' '}
                  <a className="text-blue-600 underline" href="https://goaptive.com/build-a-plan">
                    Get a quote!
                  </a>
                </p>
              </div>

              <form onSubmit={verifyCustomerInPestRoutes} className="mt-4">
                <div>
                  <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    Email Address
                  </label>
                  <div className="relative mt-2 rounded-md shadow-sm">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      value={email}
                      onChange={handleInputChange} // Handle input change and clear errors
                      className={`mt-1 block w-full shadow-sm sm:text-sm p-2 border rounded-md fs-exclude ${
                        error ? 'border-red-500' : 'border-gray-200'
                      }`}
                    />
                    {isLoading && (
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <svg
                          className="animate-spin h-5 w-5 text-gray-500"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                        </svg>
                      </div>
                    )}
                    {error && !isLoading && (
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <ExclamationCircleIcon aria-hidden="true" className="h-5 w-5 text-red-500" />
                      </div>
                    )}
                  </div>
                  {error && (
                    <p id="email-error" className="mt-2 text-sm text-red-600">
                      {error}
                    </p>
                  )}
                </div>

                <div className="d-grid gap-2 buttons-wrapper">
                  <button
                    type="submit"
                    className="w-full py-2.5 mt-3 text-white bg-aptive-900 hover:bg-aptive-800 disabled:bg-gray-50 disabled:border-none disabled:text-gray-400 pl-6 pr-6 border text-sm rounded shadow-sm block text-center"
                    disabled={isLoading} // Disable button while loading
                  >
                    Next
                  </button>

                  <p className="text-sm mt-4">Having trouble? Email customersupport@goaptive.com for personal assistance.</p>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="lg:flex flex-col hidden w-1/2 pt-5 pr-5">
          <img src={SignInBg} className="w-full h-full object-cover rounded-[10px] max-w-[865px]" alt="Sign In Bg" />
        </div>
      </div>
    </div>
  );
};

export default AuthPage;
