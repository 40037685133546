import { SpotWindow } from "./request";

export enum AppointmentStatus {
  Rescheduled = -2,
  Cancelled = -1,
  Pending = 0,
  Completed = 1,
  NoShow = 2,
}

export type AppointmentWindow = SpotWindow | 'Timed';