import LoadingSpinner from 'components/LoadingSpinner';
import PageTitle from 'components/PageTitle';
import { useTrackingView } from '../../shared/hooks/useTrackingView';
import { withAuthenticationRequired } from 'shared/hooks/AptiveAuth';
import Toggle from './components/Toggle';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { submitCustomerSchedulingPreferences } from 'services/CustomerService';
import { useRecoilState, useRecoilValue } from 'recoil';
import aptiveUserAccountId from 'app-recoil/selectors/aptive-user-account-id';
import { DayOfWeek, TimeWindow } from 'types';
import { debounce } from 'lodash';
import { customerInfoDataState } from 'app-recoil/atoms/customer-info';
import useIsSchedulingPreferencesEnabled from 'shared/hooks/useIsSchedulingPreferencesEnabled';
import { Navigate } from 'react-router-dom';
import SchedulingPreferencesPageSkeleton from './components/SchedulingPreferencesPageSkeleton';

interface TimePreference {
  label: string;
  description: string;
  name: string;
  value: TimeWindow|null;
}

interface DayPreference {
  name: string;
  value: DayOfWeek|null;
}

const timePreferences: Record<'anytime'|'morning'|'afternoon_evening',TimePreference> = {
  anytime: {
    label: 'Anytime: 8am - 5pm',
    description: 'Available all day from morning to evening',
    name: 'Anytime',
    value: null,
  },
  morning: {
    label: 'Morning: 8am - 12pm',
    description: 'Available during morning hours only',
    name: 'Morning',
    value: 'AM',
  },
  afternoon_evening: {
    label: 'Afternoon/Evening: 1pm - 5pm',
    description: 'Available in the afternoon to early evening',
    name: 'Afternoon-Evening',
    value: 'PM',
  },
};

const dayPreferences: Record<'anyday'|'monday'|'tuesday'|'wednesday'|'thursday'|'friday'|'saturday',DayPreference> = {
  anyday: {
    name: 'Any day',
    value: null,
  },
  monday: {
    name: 'Monday',
    value: 1,
  },
  tuesday: {
    name: 'Tuesday',
    value: 2,
  },
  wednesday: {
    name: 'Wednesday',
    value: 3,
  },
  thursday: {
    name: 'Thursday',
    value: 4,
  },
  friday: {
    name: 'Friday',
    value: 5,
  },
  saturday: {
    name: 'Saturday',
    value: 6,
  },
};

const submitCustomerSchedulingPreferencesDebounce = debounce(
  (
    accountId: string, 
    selectedDayPreference: DayPreference, 
    selectedTimePreference: TimePreference,
  ): Promise<undefined> => submitCustomerSchedulingPreferences(accountId, {
    preferredDay: selectedDayPreference.value,
    preferredTime: selectedTimePreference.value,
  }),
  1000,
  {
    leading: false,
    trailing: true
  }
);

const SchedulingPreferencesPage = () => {
  useTrackingView();

  const accountId = useRecoilValue(aptiveUserAccountId);

  const [selectedTimePreference, setSelectedTimePreference] = useState<TimePreference>(timePreferences.anytime);
  const [selectedDayPreference, setSelectedDayPreference] = useState<DayPreference>(dayPreferences.anyday);

  const [customerInfo, setCustomerInfo] = useRecoilState(customerInfoDataState);

  const { isSchedulingPreferencesEnabled, isSchedulingPreferencesEnabledLoading } = useIsSchedulingPreferencesEnabled();

  useEffect(
    () => {
      if (customerInfo){
        const selectedDayPreference = Object.values(dayPreferences).find(
          (dayPreference) => dayPreference.value === customerInfo.schedulingPreferences.preferredDay
        ) ?? dayPreferences.anyday;

        const selectedTimePreference = Object.values(timePreferences).find(
          (timePreference) => timePreference.value === customerInfo.schedulingPreferences.preferredTime
        ) ?? timePreferences.anytime;

        setSelectedDayPreference(selectedDayPreference);
        setSelectedTimePreference(selectedTimePreference);
      }
    },
    [customerInfo]
  );

  const updateSchedulingPreferences = useCallback(
    (preferredTime: TimePreference, preferredDay: DayPreference) => {
      setCustomerInfo((customerInfo) => customerInfo ? { 
        ...customerInfo, 
        schedulingPreferences: {
          preferredDay: preferredDay.value,
          preferredTime: preferredTime.value,
        }
      } : null);
    },
    [setCustomerInfo]
  );

  const onTimePreferenceChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = e.target;
  
      const checkedTimePreference = Object.values(timePreferences).find(
        (timePreference) => timePreference.name === name
      );
  
      if (checked && checkedTimePreference) {
        updateSchedulingPreferences(checkedTimePreference, selectedDayPreference);
        submitCustomerSchedulingPreferencesDebounce(accountId, selectedDayPreference, checkedTimePreference);
      } else {
        updateSchedulingPreferences(timePreferences.anytime, selectedDayPreference);
        submitCustomerSchedulingPreferencesDebounce(accountId, selectedDayPreference, timePreferences.anytime);
      }
    },
    [accountId, selectedDayPreference, updateSchedulingPreferences]
  );  

  const onDayPreferenceChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.target;
      
      const selectedDayPreference = Object.values(dayPreferences).find(
        (dayPreference) => dayPreference.value === (value === 'null' ? null : +value)
      );

      if (selectedDayPreference) {
        updateSchedulingPreferences(selectedTimePreference, selectedDayPreference);
        submitCustomerSchedulingPreferencesDebounce(accountId, selectedDayPreference, selectedTimePreference);
      } else {
        updateSchedulingPreferences(selectedTimePreference, dayPreferences.anyday);
        submitCustomerSchedulingPreferencesDebounce(accountId, dayPreferences.anyday, selectedTimePreference);
      }
    },
    [accountId, selectedTimePreference, updateSchedulingPreferences]
  );

  if (!isSchedulingPreferencesEnabledLoading && !isSchedulingPreferencesEnabled) {
    return <Navigate to="/appointments/upcoming" replace />;
  }

  return (
    <>
      <PageTitle title="Scheduling Preferences" />

      {
        isSchedulingPreferencesEnabledLoading 
          ? <SchedulingPreferencesPageSkeleton /> 
          : (
            <div className='bg-white shadow overflow-hidden sm:rounded-md mb-5'>
              <ul className="divide-y divide-gray-200">
                <li key="time-of-day-preference">
                  <div className="px-4 py-8 sm:px-6">
                    <div className="space-y-1">
                      <p className="text-[18px] font-medium">Time of day Preference</p>
                      <p className="flex text-[14px] items-center text-gray-500 sm:mt-0">Select which appointment window you would prefer for your regularly scheduled appointments</p>
                    </div>
                  </div>
                </li>
                {
                  Object.values(timePreferences).map((timePreference, index) => 
                    <li key={`time-preference-option-${index}`}>
                      <div className="px-4 py-5 sm:px-6">
                        <div className="flex items-center justify-between">
                          <div className="w-1/2 md:w-1/3">
                            <p className="text-[14px] font-medium">{timePreference.label}</p>
                            <p className="flex text-[14px] items-center text-gray-500 sm:mt-0">{timePreference.description}</p>
                          </div>
                          <div className="ml-auto flex items-center">
                            <Toggle
                              id={`time-preference-${timePreference.name}`}
                              name={timePreference.name}
                              onChange={onTimePreferenceChange}
                              checked={timePreference.value === selectedTimePreference.value}
                            />
                          </div>
                        </div>
                      </div>
                    </li>)
                }
                <li key="day-of-week-preference">
                  <div className="px-4 py-8 sm:px-6">
                    <div className="space-y-1">
                      <p className="text-[18px] font-medium">Day of week Preference</p>
                      <p className="flex text-[14px] items-center text-gray-500 sm:mt-0">Select which day of the week you would prefer for your regularly scheduled appointments</p>
                    </div>
                  </div>
                </li>
                <li key="day-preference-option">
                  <div className="px-4 py-5 sm:px-6">
                    <div className="flex items-center justify-between">
                      <div className="w-1/2 md:w-1/3">
                        <p className="text-[14px] font-medium">Preferred day of week</p>
                        <p className="flex text-[14px] items-center text-gray-500 sm:mt-0">Preferred day for service: Specify your choice</p>
                      </div>
                      <div className="ml-auto flex items-center">
                        <select
                          className="focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm p-2 pr-8 border border-gray-300 rounded-md fs-exclude"
                          value={selectedDayPreference.value ?? 'null'}
                          onChange={onDayPreferenceChange}
                        >
                          {
                            Object.values(dayPreferences).map(
                              (dayPreference) => (
                                <option 
                                  key={`day-preference-${dayPreference.name}`} 
                                  title={dayPreference.name} 
                                  value={dayPreference.value ?? 'null'}>
                                    {dayPreference.name}
                                </option>
                              ))
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          )
      }
    </>
  );
};

export default withAuthenticationRequired(SchedulingPreferencesPage, {
  onRedirecting: () => <LoadingSpinner centered />,
});
