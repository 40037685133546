import { CustomerSpot, SpotWindow } from "types/request";

export function spotTime(spot: CustomerSpot): string {
    return {
        AM: '8am - 12pm',
        PM: '1pm - 5pm',
        AT: '8am - 5pm',
    }[spot.attributes.window];
}

export const anytimeSpotCounterpart = (spot: CustomerSpot): CustomerSpot => ({
  ...spot,
  attributes: {
    ...spot.attributes,
    window: 'AT',
  },
});

export const AMPMSpotCounterparts = (spot: CustomerSpot): CustomerSpot[] => (['AM', 'PM'] as Array<SpotWindow>).map((window) => ({
  ...spot,
  attributes: {
    ...spot.attributes,
    window: window,
  },
}));