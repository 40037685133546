import React from 'react';

const SchedulingPreferencesPageSkeleton: React.FC = () => {
    return (
        <div className="bg-white shadow overflow-hidden sm:rounded-md mb-5 animate-pulse">
            <ul className="divide-y divide-gray-200">
            <li>
                <div className="px-4 py-8 sm:px-6">
                <div className="space-y-1">
                    <div className="h-5 bg-gray-300 rounded-full w-64 mb-2" />
                    <div className="h-3 bg-gray-300 rounded-full w-80" />
                </div>
                </div>
            </li>

            {Array.from({ length: 3 }).map((_, index) => (
                <li key={`time-preference-${index}`}>
                <div className="px-4 py-5 sm:px-6">
                    <div className="flex items-center justify-between">
                    <div className="w-1/2 md:w-1/3">
                        <div className="h-4 bg-gray-300 rounded-full w-48 mb-2" />
                        <div className="h-3 bg-gray-300 rounded-full w-64" />
                    </div>
                    <div className="ml-auto flex items-center">
                        <div className="h-6 w-12 bg-gray-300 rounded-full" />
                    </div>
                    </div>
                </div>
                </li>
            ))}

            <li>
                <div className="px-4 py-8 sm:px-6">
                <div className="space-y-1">
                    <div className="h-5 bg-gray-300 rounded-full w-64 mb-2" />
                    <div className="h-3 bg-gray-300 rounded-full w-80" />
                </div>
                </div>
            </li>

            <li>
                <div className="px-4 py-5 sm:px-6">
                <div className="flex items-center justify-between">
                    <div className="w-1/2 md:w-1/3">
                    <div className="h-4 bg-gray-300 rounded-full w-48 mb-2" />
                    <div className="h-3 bg-gray-300 rounded-full w-64" />
                    </div>
                    <div className="ml-auto flex items-center">
                    <div className="h-8 bg-gray-300 rounded-md w-24" />
                    </div>
                </div>
                </div>
            </li>
            </ul>
        </div>
    );
};

export default SchedulingPreferencesPageSkeleton;
