import { useState, useEffect } from 'react';
import { useConfigCatClient } from 'configcat-react';
import { User } from 'configcat-common';
import { COMPANIES_LOGOS } from 'constants/auth';

export const useCobrandingLogo = () => {
  const [cobrandingLogo, setCobrandingLogo] = useState<string | null>(null);
  const configCatClient = useConfigCatClient();

  useEffect(() => {
    const userCompany = localStorage.getItem('userCompany');

    if (userCompany) {
      const user: User = {
        identifier: userCompany,
        custom: {
          company: userCompany
        }
      };

      configCatClient.getValueAsync('whichCompaniesHaveCobrandingEnabled', false, user)
        .then(isCobrandingEnabled => {
          if (isCobrandingEnabled && COMPANIES_LOGOS[userCompany]) {
            setCobrandingLogo(COMPANIES_LOGOS[userCompany]);
          }
        });
    }
  }, [configCatClient]);

  return cobrandingLogo;
};
