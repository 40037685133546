import aptiveUserAccountId from "app-recoil/selectors/aptive-user-account-id";
import AptiveModal from "components/modals/AptiveModal";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { submitPostrescheduleFeedback } from "services/CustomerService";

interface PostrescheduleFeedbackModalProps {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  originalAppointmentId: string;
  newAppointmentId: string;
}
  
const rescheduleReasons = {
    unavailable: 'Need inside treated, can\'t be home at scheduled time',
    weather: 'Weather concerns',
    directive: 'Service professional directed me to reschedule',
    other: 'Other',
};

const PostrescheduleFeedbackModal: React.FC<PostrescheduleFeedbackModalProps> = ({ isOpen, setOpen, originalAppointmentId, newAppointmentId }) => {
    const navigate = useNavigate();
    const accountId = useRecoilValue(aptiveUserAccountId);
    const [rescheduleReason, setRescheduleReason] = useState(rescheduleReasons.unavailable);
    const [rescheduleReasonDetails, setRescheduleReasonDetails] = useState('');

    useEffect(() => {
        if (rescheduleReason !== rescheduleReasons.other){
            setRescheduleReasonDetails('');
        }
    },[rescheduleReason,setRescheduleReasonDetails]);

    const renderContent = (): React.ReactNode => {
      return (
        <>
          <div className="font-bold text-gray-900 text-font-18px leading-24px mb-4">What prompted you to reschedule your appointment?</div>
  
          <select 
            name="reschedule-reason-select" 
            id="reschedule-reason-select"
            className="focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm p-2 pr-8 border border-gray-300 rounded-md fs-exclude"
            onChange={(event: ChangeEvent<HTMLSelectElement>) => setRescheduleReason(event.target.value)}
          >
            {
              Object.values(rescheduleReasons).map(
                (reason: string) => <option key={reason} title={reason}>{reason}</option>
              )
            }
          </select>
  
         {rescheduleReason === rescheduleReasons.other && <textarea
            name="reschedule-reason-text"
            id="reschedule-reason-text"
            className="border border-gray-300 w-full rounded-md outline-none h-155px p-2 text-gray-500 fs-exclude mt-4"
            onChange={(event: ChangeEvent<HTMLTextAreaElement>) => setRescheduleReasonDetails(event.target.value)}
            placeholder="Kindly tell us about it"
          />}
        </>
      );
    };

    return (
        <AptiveModal
            description={renderContent()}
            approveButtonText="Submit"
            approveButtonColor="primary"
            isOpen={isOpen}
            setOpen={setOpen}
            isLoading={false}
            confirmCallback={() => {
                submitPostrescheduleFeedback({
                    customer_id: Number(accountId),
                    original_appointment_id: Number(originalAppointmentId),
                    new_appointment_id: Number(newAppointmentId),
                    reason: rescheduleReason,
                    reason_details: rescheduleReasonDetails
                });
                
                if (window.location.hash) {
                    navigate(window.location.pathname, { replace: true });
                }

                setOpen(false);
            }}
            trackingInfo={{ name: 'reschedule_feedback', buttonApproval: 'click_confirm', buttonCancel: 'click_cancel' }}
        />
    );
};

export default PostrescheduleFeedbackModal;
