import { useMemo } from 'react';
import { User } from 'configcat-common';
import { useRecoilValue } from 'recoil';
import aptiveUserAccountId from 'app-recoil/selectors/aptive-user-account-id';
import { useFeatureFlag } from 'configcat-react';
import { customerInfoDataState } from 'app-recoil/atoms/customer-info';

const useIsSchedulingPreferencesEnabled = () => {
  const accountId = useRecoilValue(aptiveUserAccountId);
  const customerInfo = useRecoilValue(customerInfoDataState);

  const user = useMemo(() => new User(accountId), [accountId]);

  const {
    value: whichOfficesAreEnabledForSchedulingPreference, 
    loading: whichOfficesAreEnabledForSchedulingPreferenceLoading
  } = useFeatureFlag('whichOfficesAreEnabledForSchedulingPreference', '');

  const {
    value: isSchedulingPreferenceVisibleToCustomer,
    loading: isSchedulingPreferenceVisibleToCustomerLoading
  } = useFeatureFlag('isSchedulingPreferenceVisibleToCustomer', false, user);

  const officesEnabledForSchedulingPreference = useMemo(
    () => (whichOfficesAreEnabledForSchedulingPreference as string).split(',').map((office) => Number(office.trim())),
    [whichOfficesAreEnabledForSchedulingPreference]
  );

  const isSchedulingPreferencesEnabled = useMemo(
    () => !!(customerInfo
        && officesEnabledForSchedulingPreference.includes(customerInfo.officeId)
        && isSchedulingPreferenceVisibleToCustomer),
    [
        customerInfo, 
        officesEnabledForSchedulingPreference, 
        isSchedulingPreferenceVisibleToCustomer
    ]
  );

  const isSchedulingPreferencesEnabledLoading = useMemo(
    () => !customerInfo
        || whichOfficesAreEnabledForSchedulingPreferenceLoading 
        || isSchedulingPreferenceVisibleToCustomerLoading,
    [
        customerInfo,
        whichOfficesAreEnabledForSchedulingPreferenceLoading,
        isSchedulingPreferenceVisibleToCustomerLoading
    ]
  );
  
  return { 
    isSchedulingPreferencesEnabled, 
    isSchedulingPreferencesEnabledLoading 
  };
};

export default useIsSchedulingPreferencesEnabled;
