import { FC } from 'react';
import SpotItem from 'components/SpotItem';
import { DatetimeHelper } from 'helpers/datetime';
import moment from 'moment';
import { SpotWindow } from 'types/request';
import { useFeatureFlag } from 'configcat-react';

type SlotProps = {
  disabled: boolean;
  selected: boolean;
};
type Props = {
  date: string;
  atSlot: SlotProps;
  amSlot: SlotProps;
  pmSlot: SlotProps;
  onSelectWindow: (window: SpotWindow) => void;
};
const AppointmentSlot: FC<Props> = ({ date, atSlot, amSlot, pmSlot, onSelectWindow }) => {
  const { value: isExpandedTimeSlotOptionsEnabled } = useFeatureFlag('isExpandedTimeSlotOptionsEnabled', false);

  return (isExpandedTimeSlotOptionsEnabled ?
    <div className="breakpoint-723:w-[674px] w-full breakpoint-max-723:flex-col flex justify-between items-center mb-[16px]">
      <div className="w-[148px] text-[#111827] text-[14px] font-bold breakpoint-max-723:text-center breakpoint-max-723:mb-[8px] text-right">
        {DatetimeHelper.format(moment(date), 'dddd D')}
      </div>
      <div className="flex justify-between items-center breakpoint-max-723:w-[100%] w-[510px]">
        <div className="breakpoint-max-723:w-[30%] w-[198px]">
          <SpotItem label={<><span className='font-medium'>Anytime</span> <span className='text-xs italic'>(8am - 5pm)</span></>} selected={atSlot.selected} disabled={atSlot.disabled} onClick={() => onSelectWindow('AT')} />
        </div>
        <div className="breakpoint-max-723:w-[30%] w-[148px]">
          <SpotItem label={<><span className='font-medium'>8am - 12pm</span></>} selected={amSlot.selected} disabled={amSlot.disabled} onClick={() => onSelectWindow('AM')} />
        </div>
        <div className="breakpoint-max-723:w-[30%] w-[148px]">
          <SpotItem label={<><span className='font-medium'>1pm - 5pm</span></>} selected={pmSlot.selected} disabled={pmSlot.disabled} onClick={() => onSelectWindow('PM')} />
        </div>
      </div>
    </div>
    : 
    <div className="breakpoint-575:w-[476px] w-full breakpoint-max-575:flex-col flex justify-between items-center mb-[16px]">
      <div className="w-[148px] text-[#111827] text-[14px] font-bold breakpoint-max-575:text-center breakpoint-max-575:mb-[8px] text-right">
        {DatetimeHelper.format(moment(date), 'dddd D')}
      </div>
      <div className="flex justify-between items-center breakpoint-max-575:w-[100%] w-[312px]">
        <div className="breakpoint-max-575:w-[48%] w-[148px]">
          <SpotItem label={<><span className='font-medium'>8am - 12pm</span></>} selected={amSlot.selected} disabled={amSlot.disabled} onClick={() => onSelectWindow('AM')} />
        </div>
        <div className="breakpoint-max-575:w-[48%] w-[148px]">
          <SpotItem label={<><span className='font-medium'>1pm - 5pm</span></>} selected={pmSlot.selected} disabled={pmSlot.disabled} onClick={() => onSelectWindow('PM')} />
        </div>
      </div>
    </div>
  );
};

export default AppointmentSlot;
