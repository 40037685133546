import { ChangeEvent } from 'react';

export default function Toggle({ id, name, checked, onChange }: { id?: string; name?: string; checked?: boolean; onChange?: (e: ChangeEvent<HTMLInputElement>) => void }) {
    return (
      <label className="relative inline-flex items-center cursor-pointer">
        <input
          id={id}
          name={name}
          type="checkbox"
          className="sr-only peer"
          checked={checked}
          onChange={(e) => onChange?.(e)}
        />
        <div className="w-12 h-6 bg-gray-200 rounded-full peer peer-checked:bg-green-900 peer-focus:ring-4 peer-focus:ring-gray-300 transition-all"></div>
        <div className="absolute left-1 top-1 w-4 h-4 bg-white border border-gray-300 rounded-full peer-checked:translate-x-6 peer-checked:border-green-900 flex items-center justify-center transition-all">
          <svg
            className="w-3 h-3 text-gray-400 peer-checked:hidden"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            {checked ? <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" /> : <path strokeLinecap="round" strokeLinejoin="round" d="M6 6l12 12M6 18L18 6" />}
          </svg>
        </div>
      </label>
    );
  }
  