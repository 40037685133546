import React from 'react';
import plusIcon from 'images/plus-icon.png';
import { useCobrandingLogo } from 'shared/hooks/useCobrandingLogo';

const CobrandingLogo: React.FC = () => {
  const cobrandingLogo = useCobrandingLogo();

  if (!cobrandingLogo) return null;

  return (
    <>
      <img data-testid="plus-icon" className="block h-3 w-auto mx-2" src={plusIcon} alt="Plus Icon" />
      <img data-testid="company-logo" className="block h-5 w-auto" src={cobrandingLogo} alt="Company Logo" />
    </>
  );
};

export default CobrandingLogo;
