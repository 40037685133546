import moment from "moment";
import { AppointmentWindow } from "types/appointment";
import { Appointment } from "types/request";

export function appointmentTime(appointment: Appointment): string {
    return {
        AM: '8am - 12pm',
        PM: '1pm - 5pm',
        AT: '8am - 5pm',
        Timed: `${moment(appointment.start).format('ha')} - ${moment(appointment.end).format('ha')}`
    }[appointmentWindow(appointment)];
}

export function appointmentWindow(appointment: Appointment): AppointmentWindow {
    const getHour = (dateString: string): number => {
        const timePart = dateString.split('T')[1].split('-')[0];

        const [hour] = timePart.split(':').map(Number);

        return hour;
    };

    const startHour = getHour(appointment.start);
    const endHour = getHour(appointment.end);

    if (startHour === 8 && endHour === 12) {
        return 'AM';
    }

    if (startHour === 12 && endHour === 17) {
        return 'PM';
    }

    if (startHour === 8 && endHour === 17) {
        return 'AT';
    }

    return 'Timed';
}
